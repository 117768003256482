import React, { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Flex, NavigationButton, Panel, useErrorHandler } from '@mirakl/roma';

import useOrganizationContext from '../OrganizationContext';
import OrganizationRunPage from '../OrganizationRunPage';

import NoSubscription from './NoSubscription';
import SubscriptionDetailsSection from './SubscriptionDetailsSection';
import SubscriptionPriceSection from './SubscriptionPriceSection';
import useSubscriptionApi from './useSubscriptionApi';

type SubscriptionInfos = {
    currentPlan: CurrentPlanDetail;
};

export type CurrentPlanDetail = {
    displayPrice: string;
    endDate: string;
    nextBillingDate: string;
    type: 'PRO' | 'BUSINESS' | 'GROWTH' | 'START';
};

const SubscriptionPage = () => {
    const { formatMessage } = useI18n();
    const [isLoading, setIsLoading] = useState(true);
    const {
        isLegacySubscription,
        isOrganizationAdmin,
        uuid: organizationUuid,
    } = useOrganizationContext();
    const { getSubscription } = useSubscriptionApi();
    const [subscriptionInfos, setSubscriptionInfos] =
        useState<SubscriptionInfos>();
    const handleError = useErrorHandler();

    useEffect(() => {
        getSubscription(organizationUuid)
            .then((data) => {
                if (data !== undefined) {
                    setSubscriptionInfos({
                        currentPlan: {
                            nextBillingDate: data.nextBillingDate,
                            displayPrice: data.displayPrice,
                            type: data.type,
                            endDate: data.endDate,
                        },
                    });
                }
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, [organizationUuid, setIsLoading, getSubscription, handleError]);
    const showManagePlan = isLegacySubscription && isOrganizationAdmin;

    return (
        <OrganizationRunPage
            customActions={
                showManagePlan ? (
                    <NavigationButton
                        label={formatMessage({
                            id: 'organization.subscription.page.manage-plan',
                        })}
                        to={`/organizations/${organizationUuid}/paywall?callback=${window.location.origin}/organizations/${organizationUuid}/subscription`}
                    />
                ) : null
            }
            pageTitle={formatMessage({
                id: 'organization.subscription.page.title',
            })}
            size="md"
        >
            {subscriptionInfos ? (
                <Flex allowFullScreen flexDirection="row">
                    <Flex width="66%">
                        <Panel loading={isLoading}>
                            <Panel.Header
                                title={formatMessage({
                                    id: 'organization.subscription.price',
                                })}
                            />
                            <Panel.Content>
                                <SubscriptionPriceSection
                                    currentPlanDetail={
                                        subscriptionInfos.currentPlan
                                    }
                                    isOrganizationAdmin={isOrganizationAdmin}
                                />
                            </Panel.Content>
                        </Panel>
                    </Flex>
                    <Flex marginStart={4} width="33%">
                        <Panel loading={isLoading}>
                            <Panel.Header
                                title={formatMessage({
                                    id: 'organization.subscription.details',
                                })}
                            />
                            <Panel.Content>
                                <SubscriptionDetailsSection
                                    currentPlanDetail={
                                        subscriptionInfos.currentPlan
                                    }
                                    isOrganizationAdmin={isOrganizationAdmin}
                                />
                            </Panel.Content>
                        </Panel>
                    </Flex>
                </Flex>
            ) : (
                <Flex>
                    <NoSubscription />
                </Flex>
            )}
        </OrganizationRunPage>
    );
};

export default SubscriptionPage;
