import React, { type ReactNode } from 'react';
import { styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Flex,
    Icon,
    NavigationButton,
    Tooltip,
    themeColor,
    themeSpace,
} from '@mirakl/roma';

import useOrganizationContext from '../../../../OrganizationContext';
import { PaywallType } from '../../../useSubscriptionApi';

type Plan = 'start' | 'growth' | 'pro' | 'business' | 'enterprise';

const CardTitle = styled.h2`
    text-align: center;
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
`;

const CardPrice = styled.h3`
    text-align: center;
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 22px;
    line-height: 32px;

    color: ${themeColor('primary-300')};

    display: flex;
    gap: ${themeSpace(1)};
`;

const PlanButton = ({
    currentPlan,
    isLegacySubscription,
    onPlanSelected,
    plan,
}: {
    currentPlan?: boolean;
    isLegacySubscription: boolean;
    onPlanSelected: (planId: PaywallType) => void;
    plan: Plan;
}) => {
    const { formatMessage } = useI18n();

    if (currentPlan) {
        return (
            <Button
                disabled
                label={formatMessage({
                    id: 'organization.paywall.card.action.currentPlan',
                })}
                variant="ghost"
                onClick={() => {}}
            />
        );
    }

    if (plan === 'enterprise') {
        return (
            <NavigationButton
                external
                href="https://info.mirakl.com/mirakl-connect-channel-manager"
                label={formatMessage({
                    id: 'organization.paywall.card.action.contactUs',
                })}
                target="_blank"
                variant="secondary"
            />
        );
    }

    const disableSubscription = isLegacySubscription && plan === 'start';
    return (
        <Button
            disabled={disableSubscription}
            label={formatMessage({
                id: 'organization.paywall.card.action.selectPlan',
            })}
            tooltipText={
                disableSubscription
                    ? formatMessage({
                          id: 'organization.paywall.card.action.selectPlan.free.tooltip',
                      })
                    : undefined
            }
            variant="secondary"
            onClick={() => onPlanSelected(plan)}
        />
    );
};

type PaywallCardHeaderProps = {
    currentPlan?: boolean;
    heading?: ReactNode;
    onPlanSelected: (planId: PaywallType) => void;
    plan: Plan;
    price?: string;
};

const PaywallCardHeader = ({
    currentPlan,
    heading,
    onPlanSelected,
    plan,
    price,
}: PaywallCardHeaderProps) => {
    const { isLegacySubscription } = useOrganizationContext();
    const { formatMessage } = useI18n();

    return (
        <Flex
            alignItems="center"
            flexDirection="column"
            fullWidth
            gap={5}
            paddingBottom={6}
        >
            <Flex height={4}>{heading}</Flex>
            <Flex alignItems="center" flexDirection="column" gap={4}>
                <CardTitle>
                    {formatMessage({
                        id: `organization.paywall.${plan}.title`,
                    })}
                </CardTitle>
                <CardPrice>
                    {price}
                    {plan !== 'start' && plan !== 'enterprise' && (
                        <Tooltip
                            content={formatMessage({
                                id: 'organisation.paywall.price.tooltip',
                            })}
                            trigger={<Icon name="help-outline" status="done" />}
                        />
                    )}
                </CardPrice>
                <PlanButton
                    currentPlan={currentPlan}
                    isLegacySubscription={isLegacySubscription}
                    plan={plan}
                    onPlanSelected={onPlanSelected}
                />
            </Flex>
        </Flex>
    );
};

export default PaywallCardHeader;
